import { getAuth, signOut } from '@firebase/auth';
import { doc, getFirestore, onSnapshot } from '@firebase/firestore';
import { Button, Space } from 'antd';
import moment from 'moment';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ChangeEmailAddressDialog from '../components/ChangeEmailAddressDialog';
import ResendVerificationEmailButton from '../components/ResendVerificationEmailButton';
import { PERMISSION_SETTING_BILLING } from '../constants/permission';
import { SUBSCRIPTION_STATUS_CANCELLED, SUBSCRIPTION_STATUS_INCOMPLETE, SUBSCRIPTION_STATUS_INCOMPLETE_EXPIRED, SUBSCRIPTION_STATUS_PAST_DUE, SUBSCRIPTION_STATUS_TRIALING, SUBSCRIPTION_STATUS_UNPAID } from '../constants/subscription';
import { useAccess, useAdmin, useEamilVerified, useIsEBAccess, useIsMockMode, useRealmId } from './AuthenticationContext';
import { useMockExpire } from './InstallationContext';

const SubscriptionContext = createContext();

export function SubscriptionProvider({ children }) {
  const realmId = useRealmId();
  const admin = useAdmin();
  const ebAccess = useIsEBAccess();
  const isMock = useIsMockMode();
  const timestamp = useMockExpire();
  const emailVerified = useEamilVerified();
  const [account, setAccount] = useState();
  const [changeEmailVisible, setChangeEmailVisible] = useState(false);
  const { paymentMethod } = account?.data() || {};
  const billingAccess = useAccess(PERMISSION_SETTING_BILLING);

  useEffect(() => {
    if (realmId) {
      const handler = onSnapshot(doc(getFirestore(), `billings/${realmId}`), setAccount);
      return handler;
    }
  }, [realmId]);

  const context = useMemo(() => {
    if (isMock) {
      return {
        billingAccount: account,
        banner: {
          type: 'warning',
          message: <FormattedMessage defaultMessage='Your are in mock sign in mode of <b>{name}</b>, account will expire {fromNow}'
            values={{ name: admin?.name, b: t => <b>{t}</b>, fromNow: timestamp ? moment.unix(timestamp).fromNow() : '' }} />,
          action: <Button size='small' onClick={() => signOut(getAuth())}><FormattedMessage tagName='span' defaultMessage='Sign out' /></Button>
        }
      };
    }

    let banner;

    switch (account?.get('subscription.status')) {
      case SUBSCRIPTION_STATUS_TRIALING:
        if (!paymentMethod) {
          banner = {
            type: 'info',
            message: <FormattedMessage defaultMessage='Your trial will end {fromNow}' values={{ fromNow: moment.unix(account.get('subscription.trial_end')).fromNow() }} />,
            action: billingAccess ? <Button size='small' href='/dashboard/billing/account'><FormattedMessage tagName='span' defaultMessage='Subscribe now' /></Button> : null
          };
        }
        break;
      case SUBSCRIPTION_STATUS_CANCELLED:
        banner = {
          type: 'warning',
          message: <FormattedMessage defaultMessage='Your subscription has been cancelled, you will still be able to access your account until your current licence expires.' />
        };
        break;
      case SUBSCRIPTION_STATUS_INCOMPLETE:
      case SUBSCRIPTION_STATUS_INCOMPLETE_EXPIRED:
      case SUBSCRIPTION_STATUS_PAST_DUE:
      case SUBSCRIPTION_STATUS_UNPAID:
        banner = {
          type: 'error',
          message: <FormattedMessage defaultMessage='Your subscription has been expired, please check your payment status for more information.' />,
          action: billingAccess ? <Button size='small' href='/dashboard/billing/account'><FormattedMessage tagName='span' defaultMessage='Go to billing' /></Button> : null
        };
        break;
    }

    if (!banner && admin && !emailVerified) {
      banner = {
        type: 'warning',
        message: <FormattedMessage defaultMessage={`You haven't verify your email address yet, Please confirm your email address <b>{address}</b>`}
          values={{ address: admin.email, b: t => <b>{t}</b> }} />,
        action: (
          <Space>
            <Button size='small' onClick={() => setChangeEmailVisible(true)}><FormattedMessage defaultMessage='Change email address' /></Button>
            <ResendVerificationEmailButton />
          </Space>
        )
      };
    }

    return {
      billingAccount: account,
      banner
    };
  }, [isMock, account, emailVerified, admin, billingAccess, timestamp, ebAccess]);

  return (
    <SubscriptionContext.Provider value={context}>
      {children}
      <ChangeEmailAddressDialog visible={changeEmailVisible} onCancel={() => setChangeEmailVisible(false)} />
    </SubscriptionContext.Provider>
  );
}

export const useBillingAccount = () => useContext(SubscriptionContext).billingAccount;
export const useAlertBanner = () => useContext(SubscriptionContext).banner;
export const useSubscription = () => useContext(SubscriptionContext).billingAccount?.get('subscription');
export const usePaymentMethod = () => useContext(SubscriptionContext).billingAccount?.get('paymentMethod');
export const useCompanyName = () => useContext(SubscriptionContext).billingAccount?.get('name');